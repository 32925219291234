<template>
  <div class="d-flex flex-column flex-root" id="kt_app_root">
    <div class="d-flex flex-column flex-column-fluid flex-lg-row">
      <div
        class="d-flex flex-center justify-content-center w-lg-50 p-10"
        style="margin: auto"
      >
        <div class="card rounded-3 w-md-550px">
          <div class="card-body p-10 p-lg-20">
            <form
              class="form w-100"
              novalidate="novalidate"
              id="kt_sign_in_form"
              @submit.prevent="handleLogin"
            >
              <div class="text-center mb-11">
                <img
                  src="@/assets/media/logos/logo-horizontal.png"
                  alt=""
                  class="min-w-100 w-100"
                />

                <h1 class="text-dark fw-bolder mb-3 mt-5">
                  Ingresar al Sistema
                </h1>
                <div class="text-gray-500 fw-semibold fs-6"></div>
              </div>

              <div class="fv-row mb-8">
                <input
                  type="text"
                  placeholder="Correo Electrónico"
                  name="email"
                  autocomplete="off"
                  class="form-control bg-transparent"
                  v-model="formLogin.email"
                  required
                />
              </div>
              <div class="fv-row mb-3">
                <input
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  autocomplete="off"
                  class="form-control bg-transparent"
                  v-model="formLogin.password"
                  required
                />
              </div>

              <div class="fv-row mb-3" v-if="errorMsg != ''">
                <div
                  class="
                    alert alert-danger
                    align-items-center
                    p-5
                    mb-10
                    text-center
                  "
                >
                  <div class="text-center">
                    <span class="text-center" v-html="errorMsg"></span>
                  </div>
                </div>
              </div>

              <div
                class="
                  d-flex
                  flex-stack flex-wrap
                  gap-3
                  fs-base
                  fw-semibold
                  mb-8
                "
              >
                <div></div>
                <a
                  @click="handleLostPassword"
                  class="link-primary cursor-pointer"
                  >¿Olvido su contraseña?</a
                >
              </div>
              <div class="d-grid mb-10">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  class="btn btn-primary"
                >
                  <span class="indicator-label">Ingresar</span>
                  <span class="indicator-progress"
                    >Validando credenciales...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="kt_modal_1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Recuperar contraseña</h5>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="stepper stepper-pills" id="kt_stepper_lost_password">
                <div class="stepper-nav flex-center flex-wrap mb-10 d-none">
                  <div
                    class="stepper-item mx-8 my-4 current"
                    data-kt-stepper-element="nav"
                  >
                    <!--begin::Step 1-->
                    <div class="stepper-wrapper d-flex align-items-center">
                      <div class="stepper-icon w-40px h-40px">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">1</span>
                      </div>

                      <div class="stepper-label">
                        <h3 class="stepper-title">
                          Ingrese su correo electrónico
                        </h3>
                      </div>
                    </div>

                    <div class="stepper-line h-40px"></div>
                  </div>
                  <!--end::Step 1-->

                  <!--begin::Step 2-->
                  <div
                    class="stepper-item mx-8 my-4"
                    data-kt-stepper-element="nav"
                  >
                    <div class="stepper-wrapper d-flex align-items-center">
                      <div class="stepper-icon w-40px h-40px">
                        <i class="stepper-check fas fa-check"></i>
                        <span class="stepper-number">2</span>
                      </div>

                      <div class="stepper-label">
                        <h3 class="stepper-title">Codigo de Verificación</h3>
                      </div>
                    </div>

                    <div class="stepper-line h-10px"></div>
                  </div>
                  <!--end::Step 2-->
                </div>
                <!--end::Nav-->

                <!--begin::Form-->
                <form
                  class="form mx-auto"
                  novalidate="novalidate"
                  id="kt_stepper_example_basic_form"
                >
                  <!--begin::Group-->
                  <div class="mb-5">
                    <!--begin::Step 1-->
                    <div
                      class="flex-column current"
                      data-kt-stepper-element="content"
                    >
                      <div class="fv-row">
                        <label class="form-label">Correo electrónico</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': lostEmailValid == false,
                            'is-valid':
                              lostEmailValid == true &&
                              lostEmail.length > 0 &&
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                lostEmail
                              ) == true,
                          }"
                          name="lostEmail"
                          id="lostEmail"
                          v-model="lostEmail"
                          required
                        />
                        <div
                          class="invalid-feedback"
                          v-if="lostEmailError.length > 0"
                          v-html="lostEmailError"
                        ></div>
                      </div>
                    </div>
                    <!--begin::Step 1-->

                    <!--begin::Step 2-->
                    <div class="flex-column" data-kt-stepper-element="content">
                      <!--begin::Input group-->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label">Escribe el código</label>
                        <br />
                        <small
                          v-html="
                            `Hemos enviado por correo electrónico a
                          ${lostCodeEmail} el código que hace falta para
                          iniciar sesión.`
                          "
                        ></small>

                        <input
                          type="text"
                          class="form-control form-control-solid"
                          placeholder=""
                          v-model="lostCode"
                          id="lostCode"
                        />
                        <div
                          class="text-danger"
                          v-if="lostCodeError.length > 0"
                          v-html="lostCodeError"
                        ></div>
                      </div>
                    </div>
                    <!--begin::Step 2 -->
                  </div>

                  <div class="d-flex flex-stack">
                    <div class="me-2">
                      <button
                        type="button"
                        class="btn btn-light btn-active-light-primary"
                        data-kt-stepper-action="previous"
                      >
                        Atras
                      </button>
                    </div>

                    <div>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-kt-stepper-action="submit"
                        id="kt_code_login"
                        @click="handleLoginCode"
                      >
                        <span class="indicator-label"> Iniciar Sesión </span>
                        <span class="indicator-progress">
                          Validando...
                          <span
                            class="
                              spinner-border spinner-border-sm
                              align-middle
                              ms-2
                            "
                          ></span>
                        </span>
                      </button>

                      <button
                        type="button"
                        class="btn btn-primary"
                        data-kt-stepper-action="next"
                        id="kt_lost_token"
                      >
                        <span class="indicator-label">Siguiente</span>
                        <span class="indicator-progress"
                          >Validando correo...
                          <span
                            class="
                              spinner-border spinner-border-sm
                              align-middle
                              ms-2
                            "
                          ></span
                        ></span>
                      </button>
                    </div>
                  </div>
                </form>
                <!--end::Form-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAuth from "../composables/useAuth";
import useTemplate from "../../template/composables/useTemplate";
import { useRouter } from "vue-router";
import { ref, watch, watchEffect } from "vue";

export default {
  setup() {
    const router = useRouter();
    const {
      loginUser,
      loading,
      lostLoading,
      lostPassword,
      codeLoading,
      loginUserCode,
      menus,
    } = useAuth();

    const { setActiveMenu, setCatalogos } = useTemplate();

    const errorMsg = ref("");
    const lostEmail = ref("admin@email.com");
    const lostEmailError = ref("");
    const lostEmailValid = ref(true);

    const lostCode = ref("");
    const lostCodeValid = ref(true);
    const lostCodeEmail = ref("");
    const lostCodeError = ref("");

    const formLogin = ref({
      email: "",
      password: "",
    });

    const handleLogin = async () => {
      const result = await loginUser(formLogin.value);
      if (result.ok) {
        const activeMenu = menus.value.find(
          (item) => item.modulo == "dashboard"
        );

        setActiveMenu(activeMenu);

        router.push({ name: "dashboard" });
      } else {
        // Error
        errorMsg.value = result.message;
      }
    };

    const handleLoginCode = async () => {
      if (lostCode.value.length <= 0) {
        document.querySelector("#lostCode").classList.add("is-invalid");
        return;
      } else {
        const result = await loginUserCode({
          email: lostCodeEmail.value,
          code: lostCode.value,
        });
        if (result.ok) {
          var modal = $("#kt_modal_1");
          modal.modal("hide");
          router.push({ name: "dashboard" });
        } else {
          // Error
          lostCodeError.value = result.message;
          return;
        }
      }
    };

    const handleLostPassword = () => {
      var steps = document.querySelector("#kt_stepper_lost_password");
      var stepper = new KTStepper(steps);

      stepper.on("kt.stepper.next", function (stepper) {
        if (
          lostEmail.value.length <= 0 ||
          (lostEmail.value.length > 0 &&
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              lostEmail.value
            ) == false)
        ) {
          lostEmailValid.value = false;
          lostEmailError.value =
            "Favor de ingresar un correo electrónico valido";
        } else {
          lostPassword(lostEmail.value).then((result) => {
            if (result.ok == false) {
              lostEmailValid.value = false;
              lostEmailError.value = Array.isArray(result.message)
                ? result.message[0]
                : result.message;
            } else {
              lostEmailValid.value = true;
              lostCodeEmail.value = result.email;
              stepper.goNext();
            }
          });
        }
      });

      stepper.on("kt.stepper.previous", function (stepper) {
        stepper.goPrevious(); // go previous step
        console.log(stepper.getClickedStepIndex());
      });

      var modal = $("#kt_modal_1");
      modal.modal("show");
    };

    watch(
      () => loading.value,
      () => {
        var btnLogin = document.querySelector("#kt_sign_in_submit");
        if (loading.value) {
          btnLogin.setAttribute("data-kt-indicator", "on");
          btnLogin.setAttribute("disabled", true);
        } else {
          btnLogin.setAttribute("data-kt-indicator", "off");
          btnLogin.setAttribute("disabled", null);
          btnLogin.removeAttribute("disabled");
        }
      }
    );

    watch(
      () => lostLoading.value,
      () => {
        var btnLost = document.querySelector("#kt_lost_token");
        if (lostLoading.value) {
          btnLost.setAttribute("data-kt-indicator", "on");
          btnLost.setAttribute("disabled", true);
        } else {
          btnLost.setAttribute("data-kt-indicator", "off");
          btnLost.removeAttribute("disabled");
        }
      }
    );

    watch(
      () => codeLoading.value,
      () => {
        var btnLost = document.querySelector("#kt_code_login");
        if (codeLoading.value) {
          btnLost.setAttribute("data-kt-indicator", "on");
          btnLost.setAttribute("disabled", true);
        } else {
          btnLost.setAttribute("data-kt-indicator", "off");
          btnLost.removeAttribute("disabled");
        }
      }
    );

    watchEffect(() => lostEmailValid.value);
    watchEffect(() => lostCodeError.value);

    return {
      errorMsg,
      formLogin,
      lostEmail,
      lostEmailValid,
      lostEmailError,
      lostCodeEmail,
      lostCodeError,
      lostCode,
      handleLogin,
      handleLostPassword,
      handleLoginCode,
    };
  },
};
</script>
